import TrashIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { Box, TableCell, TextField, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../../components/UXDataTable";
import ValidatedMidasNumberTextField from "../../../../components/ValidatedMidasNumberTextField";
import LocateContainer from "../../../Tests/NewTests/LocateContainer";
import { isFieldRequired } from "../../requirement-config";
import { getConvertedCurrentAmount } from "../../shared";
import {
  MySampleInfoCols,
  ON_SITE_SAMPLE_TABLE_COLUMNS,
  SPECIAL_ANALYSIS_TABLE_COLUMNS,
  Option,
  SHIPPING_TABLE_COLUMNS,
  StyledAutocomplete,
  WORK_REQUEST_TYPES_ENUM,
  defaultWorkRequestSample,
  sampleGridFontSize,
} from "../constants";
// import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContainerRegistrationWithTest from "../../WorkRequestTypes/ContainerRegistrationWithTest";

export default function WorkRequestSampleInfoTable({
  myWorkRequest,
  setMyWorkRequest,
  unitOfMeasureLoading,
  unitOfMeasure,
  locationsLoading,
  locations,
  ownerCanEdit,
  testJson,
  setTestJson,
  isProcessing,
  containerRecommendation,
  setContainerRecommendation,
  containerRecommendationErrorCheck,
  setContainerRecommendationErrorCheck,
  isTechnicianRole,
  isCancelledOrCompleted,
}) {
  const [tempHasErrors, setTempHasErrors] = useState(true);
  const [locateContainerIsOpen, setLocateContainerIsOpen] = useState(false);
  const [locateContainerMidas, setLocateContainerMidas] = useState("");
  const [locateContainerOnlySearch, setLocateContainerOnlySearch] = useState(false);

  const canEdit = ownerCanEdit || isTechnicianRole;

  const workRequestType =
    myWorkRequest && myWorkRequest.typeName ? myWorkRequest.typeName : "";

  // const usingContainerRegistration =
  //   myWorkRequest?.typeName === WORK_REQUEST_TYPES_ENUM.CONTAINER_REGISTRATION;

  // const usingContainerRegistrationWTest =
  //   myWorkRequest?.typeName ===
  //   WORK_REQUEST_TYPES_ENUM.CONTAINER_REGISTRATION_TESTS;

  function AddNewSampleRow() {
    const copyWorkRequestSample = structuredClone(defaultWorkRequestSample);

    const copyMyWorkRequest = structuredClone(myWorkRequest);

    if (copyMyWorkRequest.id)
      copyWorkRequestSample.workRequestID = copyMyWorkRequest.id;

    copyMyWorkRequest.workRequestSamples.push(copyWorkRequestSample);

    setMyWorkRequest(copyMyWorkRequest);
  }

  function DeleteSampleRow(index) {
    const copyMyWorkRequest = structuredClone(myWorkRequest);

    copyMyWorkRequest.workRequestSamples.splice(index, 1);

    setMyWorkRequest(copyMyWorkRequest);
  }

  function handleLocateContainerOpen(open, midas, onlySearch = false) {
    setLocateContainerIsOpen(open)
    setLocateContainerMidas(midas)
    setLocateContainerOnlySearch(onlySearch)
  }

  function updateData_WorkRequestSample(property, value, index, otherProps = {}) {
    if (property === "sample" && value?.containers?.length > 0) {
      value.containers = value.containers.filter(
        (myContainer) => myContainer.containerStatusName === "Confirmed"
      );
    }
    if (property === "sample" && !value) {
      myWorkRequest.workRequestSamples[index] = defaultWorkRequestSample;
      if (myWorkRequest.typeName === WORK_REQUEST_TYPES_ENUM.SHIPPING)
        myWorkRequest.workRequestSamples[index].requestedLocationName = null;
    }
    if (
      property === "sample" &&
      value &&
      myWorkRequest.typeName === WORK_REQUEST_TYPES_ENUM.SHIPPING
    ) {
      myWorkRequest.workRequestSamples[index].requestedLocationName = "XSHIP";
    }
    if (property === "existingContainerID") {
      const workRequestSamples = myWorkRequest.workRequestSamples.map(
        (sample, idx) => {
          if (index === idx) {
            let currentContainer = sample.sample.containers.find(
              (x) => x.id === value
            );
            return {
              ...sample,
              existingContainerID: value,
              requestedAmount: value && workRequestType === WORK_REQUEST_TYPES_ENUM.SHIPPING ? null : sample.requestedAmount,
              requestedAmountUoM: value && workRequestType === WORK_REQUEST_TYPES_ENUM.SHIPPING ? null :
                sample.requestedAmountUoM ?? currentContainer?.containerSizeUoM,
              unitOfMeasure: value && workRequestType === WORK_REQUEST_TYPES_ENUM.SHIPPING ? null : sample.unitOfMeasure ?? currentContainer?.uom,
            };
          }
          return sample;
        }
      );
      setMyWorkRequest({
        ...myWorkRequest,
        workRequestSamples: workRequestSamples,
      });
    } else if (property === "requestedAmountUoM") {
      const workRequestSamples = myWorkRequest.workRequestSamples.map(
        (sample, idx) => {
          if (index === idx) {
            return {
              ...sample,
              requestedAmount: null,
              requestedAmountUoM: value?.uoMName,
              unitOfMeasure: value,
            };
          }
          return sample;
        }
      );

      setMyWorkRequest({
        ...myWorkRequest,
        workRequestSamples: workRequestSamples,
      });
    } else {
      const workRequestSamples = myWorkRequest.workRequestSamples.map(
        (sample, idx) => {
          if (index === idx) {
            return {
              ...sample,
              ...otherProps,
              [property]: value,
            };
          }
          return sample;
        }
      );

      setMyWorkRequest({
        ...myWorkRequest,
        workRequestSamples: workRequestSamples,
      });
    }
  }
  function checkDupeContainersShippingRequest(sample, containers) {
    if (!containers) return;

    const testUsedContainers = myWorkRequest.workRequestSamples
      .filter(
        (x) =>
          x.sample &&
          x.sample.sampleName === sample.sampleName &&
          x.existingContainerID
      )
      .map(
        (x) =>
          containers.find((c) => c.id === x.existingContainerID)?.containerNumber
      );

    return containers.filter(
      (x) => !testUsedContainers.includes(x.containerNumber) || x.containerStatusName === 'Confirmed'
    );
  }

  const getOriginalAmount = (totalAmt, sample) => {
    const uom = sample?.requestedAmountUoM ?? sample?.existingContainer?.containerSizeUoM;
    return parseFloat(totalAmt.split(" ")[0]) + parseFloat(sample?.requestedAmount ?? 0) + " " + uom;
  }

  if (workRequestType === WORK_REQUEST_TYPES_ENUM.ON_SITE_TRANSPORT) {
    return (
      <UXDataTableWithoutBody
        tableWidth="100%"
        cols={
          myWorkRequest.id
            ? ON_SITE_SAMPLE_TABLE_COLUMNS
            : ON_SITE_SAMPLE_TABLE_COLUMNS.slice(0, -1)
        }
        blankFirstHeader={true}
        tableId="ON_SITE_SAMPLE_TABLE"
        rowLength={myWorkRequest.workRequestSamples.length}
        enablePaging={false}
        rowsPerPage={0}
        page={0}
        handleChangePage={null}
        handleChangeRowsPerPage={null}
        noDataFoundMessage="No MIDAS Numbers Found"
        enableAddIcon={canEdit}
        addFunction={AddNewSampleRow}
        addToolTipText="Add new sample"
        isDataLoading={false}
      >
        <StyledTableBody key="WRSamplesTable">
          {myWorkRequest.workRequestSamples.map((oWorkRequestSample, index) => {
            const key = `${oWorkRequestSample?.sample?.sampleName}${oWorkRequestSample.existingContainerID}`;
            const samplesSameKey = myWorkRequest.workRequestSamples.filter(f => `${f?.sample?.sampleName}${f.existingContainerID}` === key);
            return (
              <StyledTableRow key={`WRSamplesTable~Row${index}`}>
                <StyledTableCell component="th" scope="row">
                  {index > 0 && !oWorkRequestSample.id && canEdit && (
                    <Tooltip title="Delete Sample" placement="right">
                      <TrashIcon onClick={() => DeleteSampleRow(index)} />
                    </Tooltip>
                  )}
                </StyledTableCell>

                <StyledTableCell align="left">
                  <ValidatedMidasNumberTextField
                    isDisabled={!canEdit || oWorkRequestSample.id}
                    margin="none"
                    showLabel={true}
                    fontSize={12}
                    fieldWidth="150px"
                    midasNumberObject={oWorkRequestSample.sample}
                    setMidasNumberObject={(e) => {
                      updateData_WorkRequestSample("sample", e, index);
                    }}
                    hasErrors={tempHasErrors}
                    setHasErrors={setTempHasErrors}
                    chemIDToMatch={null}
                  />
                </StyledTableCell>

                <StyledTableCell align="right">
                  <Box display={"flex"} alignItems={"center"}>
                    <Box>
                      <StyledAutocomplete
                        renderOption={(props2, option) => (
                          <Option {...props2}>{option.containerNumber}</Option>
                        )}
                        disabled={
                          oWorkRequestSample.sample === null || !canEdit
                        }
                        disablePortal
                        noOptionsText={"No Containers Found"}
                        options={oWorkRequestSample?.sample?.containers.filter(x => x.containerStatusName === 'Confirmed' || x.id === oWorkRequestSample?.existingContainerID) ?? []}
                        onChange={(e, value) => {
                          updateData_WorkRequestSample(
                            "existingContainerID",
                            value?.id,
                            index
                          );
                        }}
                        value={oWorkRequestSample?.existingContainerID}
                        getOptionLabel={(option) => {
                          return option?.containerNumber
                            ? option.containerNumber.toString()
                            : oWorkRequestSample?.sample?.containers.length > 0
                            ? oWorkRequestSample?.sample?.containers
                                .find((f) => f.id === option)
                                ?.containerNumber.toString()
                            : ``;
                        }}
                        isOptionEqualToValue={(option, value) =>
                          value?.id === option?.id
                        }
                        autoHighlight
                        style={{ marginBottom: "5px" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100px" }}
                            variant="outlined"
                            margin="dense"
                            size="small"
                            error={
                              oWorkRequestSample.sample &&
                              !oWorkRequestSample.existingContainerID
                            }
                            inputProps={{
                              ...params.inputProps,
                              style: { fontSize: sampleGridFontSize },
                            }}
                          />
                        )}
                      />
                    </Box>

                    {oWorkRequestSample.sample !== null &&
                      oWorkRequestSample.sample?.containers.length > 0 && (
                        <Box>
                          <IconButton
                            type="button"
                            aria-label="search"
                            onClick={() => {
                              oWorkRequestSample.sample !== null &&
                              oWorkRequestSample.sample?.containers.length > 0
                                ? handleLocateContainerOpen(true, oWorkRequestSample?.sample?.sampleName, (oWorkRequestSample.sample === null || !canEdit))
                                : handleLocateContainerOpen(false, '');
                            }}
                          >
                            <SearchIcon style={{ fill: "blue" }} />
                          </IconButton>
                        </Box>
                      )}
                  </Box>
                </StyledTableCell>
                {/* Description */}
                <TableCell
                  style={{ width: "200px", fontSize: sampleGridFontSize }}
                  align="left"
                >
                  {oWorkRequestSample.sample !== null
                    ? oWorkRequestSample.sample.description
                    : ""}
                </TableCell>

                {/* PSIMS ID */}
                <StyledTableCell
                  style={{ fontSize: sampleGridFontSize }}
                  align="left"
                >
                  {oWorkRequestSample.sample !== null &&
                  oWorkRequestSample.sample.psimsid !== null
                    ? oWorkRequestSample.sample.psimsid
                    : ""}
                </StyledTableCell>

                {/* Amount Available */}
                <StyledTableCell
                  style={{ width: "200px", fontSize: sampleGridFontSize }}
                  align="left"
                >
                  {getConvertedCurrentAmount(oWorkRequestSample, false, samplesSameKey)}
                </StyledTableCell>
                {
                  <LocateContainer
                    open={locateContainerIsOpen && locateContainerMidas === oWorkRequestSample?.sample?.sampleName}
                    setOpen={handleLocateContainerOpen}
                    incomingUoMObject={null}
                    midasNumber={oWorkRequestSample?.sample?.sampleName}
                    setSelectedContainer={(value) => {
                      updateData_WorkRequestSample(
                        "existingContainerID",
                        value.id,
                        index
                      );
                    }}
                    updateAllMethods={null}
                    methodInfo={null}
                    allowAllSites={true}
                    onlySearch={locateContainerOnlySearch}
                  />
                }
                {/* Location */}
                <StyledTableCell align="right" width={"180px"}>
                  <div style={{ display: "flex" }}>
                    <StyledAutocomplete
                      fullWidth
                      sx={{
                        width: "150px",
                        marginRight: 0,
                      }}
                      renderOption={(props2, option) => (
                        <Option {...props2}>{option.locationName}</Option>
                      )}
                      disabled={
                        oWorkRequestSample.sample === null || !canEdit
                      }
                      disablePortal
                      noOptionsText={
                        locationsLoading
                          ? "Loading Locations..."
                          : "No Locations Found"
                      }
                      options={locations}
                      onChange={(e, value) =>
                        updateData_WorkRequestSample(
                          "requestedLocationName",
                          value?.locationName,
                          index
                        )
                      }
                      value={oWorkRequestSample.requestedLocationName}
                      getOptionLabel={(option) =>
                        option?.locationName
                          ? option.locationName
                          : locations.length > 0
                          ? locations.find((f) => f.locationName === option)
                              ?.locationName
                          : ``
                      }
                      isOptionEqualToValue={(option, value) =>
                        value?.locationName === option?.locationName
                      }
                      autoHighlight
                      style={{ marginBottom: "5px" }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          style={{ width: "150px" }}
                          variant="outlined"
                          margin="dense"
                          size="small"
                          error={
                            oWorkRequestSample.sample &&
                            !oWorkRequestSample.requestedLocationName
                          }
                          inputProps={{
                            ...params.inputProps,
                            style: { fontSize: sampleGridFontSize },
                          }}
                          InputProps={{ ...params.InputProps }}
                        />
                      )}
                    />
                    {/* <IconButton
                      edge="end"
                      sx={{ p: "4px" }}
                      onClick={() => {
                        window.open("https://goto/asl");
                      }}
                    >
                      <OpenInNewIcon />
                    </IconButton> */}
                  </div>
                </StyledTableCell>
                {myWorkRequest.id && (
                  <StyledTableCell>
                    <TextField
                      // disabled={
                      //   oWorkRequestSample.sample === null || !canEdit
                      // }
                      value={oWorkRequestSample.deliveryLocationSublocation}
                      margin="dense"
                      size="small"
                      onChange={(v) => {
                        if (v?.target?.value) {
                          updateData_WorkRequestSample(
                            "deliveryLocationSublocation",
                            v.target.value,
                            index
                          );
                        }
                      }}
                    />
                  </StyledTableCell>
                )}
              </StyledTableRow>
            );
          })}
        </StyledTableBody>
      </UXDataTableWithoutBody>
    );
  }

  if (workRequestType === WORK_REQUEST_TYPES_ENUM.SHIPPING) {
    return (
      <UXDataTableWithoutBody
        tableWidth="100%"
        cols={
          myWorkRequest.id
            ? SHIPPING_TABLE_COLUMNS
            : SHIPPING_TABLE_COLUMNS.slice(0, -1)
        }
        blankFirstHeader={true}
        tableId="SHIPPING_TABLE"
        rowLength={myWorkRequest.workRequestSamples.length}
        enablePaging={false}
        rowsPerPage={0}
        page={0}
        handleChangePage={null}
        handleChangeRowsPerPage={null}
        noDataFoundMessage="No MIDAS Numbers Found"
        enableAddIcon={canEdit}
        addFunction={AddNewSampleRow}
        addToolTipText="Add new sample"
        isDataLoading={false}
      >
        <StyledTableBody key="WRSamplesTable">
          {myWorkRequest.workRequestSamples.map((oWorkRequestSample, index) => {
            const key = `${oWorkRequestSample?.sample?.sampleName}${oWorkRequestSample.existingContainerID}`;
            const samplesSameKey = myWorkRequest.workRequestSamples.filter(f => `${f?.sample?.sampleName}${f.existingContainerID}` === key);
            const totalAmt = getConvertedCurrentAmount(oWorkRequestSample, false, samplesSameKey);
            return (
              <StyledTableRow key={`WRSamplesTable~Row${index}`}>
                <StyledTableCell component="th" scope="row">
                  {index > 0 && !oWorkRequestSample.id && canEdit && (
                    <Tooltip title="Delete Sample" placement="right">
                      <TrashIcon onClick={() => DeleteSampleRow(index)} />
                    </Tooltip>
                  )}
                </StyledTableCell>

                <StyledTableCell align="left">
                  <ValidatedMidasNumberTextField
                    isDisabled={!canEdit || oWorkRequestSample.id}
                    margin="none"
                    showLabel={true}
                    fontSize={12}
                    fieldWidth="150px"
                    midasNumberObject={oWorkRequestSample.sample}
                    setMidasNumberObject={(e) => {
                      updateData_WorkRequestSample("sample", e, index);
                    }}
                    hasErrors={tempHasErrors}
                    setHasErrors={setTempHasErrors}
                    chemIDToMatch={null}
                  />
                </StyledTableCell>

                <StyledTableCell align="right">
                  <Box display={"flex"} alignItems={"center"}>
                    <Box>
                      <StyledAutocomplete
                        renderOption={(props2, option) => (
                          <Option {...props2}>{option.containerNumber}</Option>
                        )}
                        disabled={
                          oWorkRequestSample.sample === null || !canEdit
                        }
                        disablePortal
                        noOptionsText={"No Containers Found"}
                        options={
                          checkDupeContainersShippingRequest(
                            oWorkRequestSample?.sample,
                            oWorkRequestSample?.sample?.containers
                          ) ?? []
                        }
                        onChange={(e, value) => {
                          updateData_WorkRequestSample(
                            "existingContainerID",
                            value?.id,
                            index
                          );
                        }}
                        value={oWorkRequestSample?.existingContainerID}
                        getOptionLabel={(option) => {
                          return option?.containerNumber
                            ? option.containerNumber.toString()
                            : oWorkRequestSample?.sample?.containers.length > 0
                            ? oWorkRequestSample?.sample?.containers
                                .find((f) => f.id === option)
                                ?.containerNumber.toString()
                            : ``;
                        }}
                        isOptionEqualToValue={(option, value) =>
                          value?.id === option?.id
                        }
                        autoHighlight
                        style={{ marginBottom: "5px" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100px" }}
                            variant="outlined"
                            margin="dense"
                            size="small"
                            // error={
                            //   oWorkRequestSample.sample &&
                            //   !oWorkRequestSample.existingContainerID
                            // }
                            inputProps={{
                              ...params.inputProps,
                              style: { fontSize: sampleGridFontSize },
                            }}
                          />
                        )}
                      />
                    </Box>

                    {oWorkRequestSample.sample !== null &&
                      oWorkRequestSample.sample?.containers.length > 0 && (
                        <Box>
                          <IconButton
                            type="button"
                            aria-label="search"
                            onClick={() => {
                              oWorkRequestSample.sample !== null &&
                              oWorkRequestSample.sample?.containers.length > 0
                                ? handleLocateContainerOpen(true, oWorkRequestSample?.sample?.sampleName, (oWorkRequestSample.sample === null || !canEdit))
                                : handleLocateContainerOpen(false, '');
                            }}
                          >
                            <SearchIcon style={{ fill: "blue" }} />
                          </IconButton>
                        </Box>
                      )}
                  </Box>
                </StyledTableCell>
                {/* Description */}
                <TableCell
                  style={{ width: "200px", fontSize: sampleGridFontSize }}
                  align="left"
                >
                  {oWorkRequestSample.sample !== null
                    ? oWorkRequestSample.sample.description
                    : ""}
                </TableCell>

                {/* PSIMS ID */}
                <StyledTableCell
                  style={{ fontSize: sampleGridFontSize }}
                  align="left"
                >
                  {oWorkRequestSample.sample !== null &&
                  oWorkRequestSample.sample.psimsid !== null
                    ? oWorkRequestSample.sample.psimsid
                    : ""}
                </StyledTableCell>

                {/* UoM */}
                <StyledTableCell align="right">
                <StyledAutocomplete
                  renderOption={(props2, option) => (
                    <Option {...props2}>{option.uoMName}</Option>
                  )}
                  // disabled={oWorkRequestSample.sample === null || !canEdit || oWorkRequestSample?.existingContainerID}
                  disabled={oWorkRequestSample.sample === null || !canEdit}
                  disablePortal
                  noOptionsText={
                    unitOfMeasureLoading ? "Loading UoMs..." : "No UoMs Found"
                  }
                  options={unitOfMeasure}
                  onChange={(e, value) => {
                    updateData_WorkRequestSample(
                      "requestedAmountUoM",
                      value,
                      index
                    );
                  }}
                  value={oWorkRequestSample.requestedAmountUoM}
                  getOptionLabel={(option) =>
                    option?.uoMName
                      ? option.uoMName
                      : unitOfMeasure.length > 0
                      ? unitOfMeasure.find((f) => f.uoMName === option)?.uoMName
                      : ``
                  }
                  isOptionEqualToValue={(option, value) =>
                    value?.uoMName === option?.uoMName
                  }
                  autoHighlight
                  style={{ marginBottom: "5px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ width: "100px" }}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      error={
                        oWorkRequestSample.sample &&
                        !oWorkRequestSample.requestedAmountUoM && !oWorkRequestSample?.existingContainerID
                      }
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: sampleGridFontSize },
                      }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
              </StyledTableCell>
              {/* Original Amt.s */}
              <StyledTableCell align="left">
                {getOriginalAmount(totalAmt, oWorkRequestSample)}
                </StyledTableCell>

              {/* Requested Split Size */}
              <StyledTableCell align="left">
                <TextField
                  style={{ width: "75px", marginTop: "5px" }}
                  // disabled={oWorkRequestSample.sample === null || !canEdit || oWorkRequestSample?.existingContainerID}
                  disabled={oWorkRequestSample.sample === null || !canEdit}
                  size="small"
                  margin="dense"
                  variant="outlined"
                  inputProps={{ style: { fontSize: sampleGridFontSize } }}
                  error={
                    oWorkRequestSample.sample &&
                    !oWorkRequestSample.requestedAmount && !oWorkRequestSample?.existingContainerID
                  }
                  InputLabelProps={{ shrink: true }}
                  value={oWorkRequestSample.requestedAmount ?? ""}
                  onChange={(e) => {
                    var numberRegex = /^\d*\.?\d*$/;
                    if (
                      numberRegex.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      const totalAmtValue = parseFloat(totalAmt.split(" ")[0]);
                      const currentValue = e.target.value ? parseFloat(e.target.value) : null;
                      const totalAvailable = parseFloat(oWorkRequestSample.requestedAmount ?? 0) + totalAmtValue
                      let value = null;
                      if (currentValue || currentValue === 0) {
                        value =
                          currentValue > totalAvailable
                            ? totalAvailable < 0 ? 0 :
                              totalAvailable
                                : e.target.value;
                      }

                      updateData_WorkRequestSample(
                        "requestedAmount",
                        value,
                        index
                      );
                    }
                  }}
                />
              </StyledTableCell>

                {/* Amount Available */}
                <StyledTableCell
                  style={{ width: "200px", fontSize: sampleGridFontSize }}
                  align="left"
                >
                  {getConvertedCurrentAmount(oWorkRequestSample, false, samplesSameKey)}
                </StyledTableCell>
                {
                  <LocateContainer
                    open={locateContainerIsOpen && locateContainerMidas === oWorkRequestSample?.sample?.sampleName}
                    setOpen={handleLocateContainerOpen}
                    incomingUoMObject={null}
                    midasNumber={oWorkRequestSample?.sample?.sampleName}
                    setSelectedContainer={(value) => {
                      updateData_WorkRequestSample(
                        "existingContainerID",
                        value.id,
                        index
                      );
                    }}
                    updateAllMethods={null}
                    methodInfo={null}
                    allowAllSites={true}
                    onlySearch={locateContainerOnlySearch}
                  />
                }
                {/* Location */}
                <StyledTableCell align="right" width={"180px"}>
                  <div style={{ display: "flex" }}>
                    <StyledAutocomplete
                      fullWidth
                      sx={{
                        width: "150px",
                        marginRight: 0,
                      }}
                      renderOption={(props2, option) => (
                        <Option {...props2}>{option.locationName}</Option>
                      )}
                      disabled={
                        oWorkRequestSample.sample === null || !canEdit
                      }
                      disablePortal
                      noOptionsText={
                        locationsLoading
                          ? "Loading Locations..."
                          : "No Locations Found"
                      }
                      options={locations}
                      onChange={(e, value) =>
                        updateData_WorkRequestSample(
                          "requestedLocationName",
                          value?.locationName,
                          index
                        )
                      }
                      value={oWorkRequestSample.requestedLocationName}
                      getOptionLabel={(option) =>
                        option?.locationName
                          ? option.locationName
                          : locations.length > 0
                          ? locations.find((f) => f.locationName === option)
                              ?.locationName
                          : ``
                      }
                      isOptionEqualToValue={(option, value) =>
                        value?.locationName === option?.locationName
                      }
                      autoHighlight
                      style={{ marginBottom: "5px" }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          style={{ width: "150px" }}
                          variant="outlined"
                          margin="dense"
                          size="small"
                          error={
                            oWorkRequestSample.sample &&
                            !oWorkRequestSample.requestedLocationName
                          }
                          inputProps={{
                            ...params.inputProps,
                            style: { fontSize: sampleGridFontSize },
                          }}
                          InputProps={{ ...params.InputProps }}
                        />
                      )}
                    />
                    {/* <IconButton
                      edge="end"
                      sx={{ p: "4px" }}
                      onClick={() => {
                        window.open("https://goto/asl");
                      }}
                    >
                      <OpenInNewIcon />
                    </IconButton> */}
                  </div>
                </StyledTableCell>
                {myWorkRequest.id && (
                  <StyledTableCell>
                    <TextField
                      // disabled={
                      //   oWorkRequestSample.sample === null || !canEdit
                      // }
                      value={oWorkRequestSample.deliveryLocationSublocation}
                      margin="dense"
                      size="small"
                      onChange={(v) => {
                        if (v?.target?.value) {
                          updateData_WorkRequestSample(
                            "deliveryLocationSublocation",
                            v.target.value,
                            index
                          );
                        }
                      }}
                    />
                  </StyledTableCell>
                )}
              </StyledTableRow>
            );
          })}
        </StyledTableBody>
      </UXDataTableWithoutBody>
    );
  }

  if (
    workRequestType === WORK_REQUEST_TYPES_ENUM.CONTAINER_REGISTRATION_TESTS
  ) {
    return (
      <ContainerRegistrationWithTest
        myWorkRequest={myWorkRequest}
        setMyWorkRequest={setMyWorkRequest}
        DeleteSampleRow={DeleteSampleRow}
        updateData_WorkRequestSample={updateData_WorkRequestSample}
        testJson={testJson}
        setTestJson={setTestJson}
        tempHasErrors={tempHasErrors}
        setTempHasErrors={setTempHasErrors}
        locateContainerIsOpen={locateContainerIsOpen}
        locateContainerMidas={locateContainerMidas}
        setLocateContainerIsOpen={handleLocateContainerOpen}
        unitOfMeasureLoading={unitOfMeasureLoading}
        unitOfMeasure={unitOfMeasure}
        AddNewSampleRow={AddNewSampleRow}
        isProcessing={isProcessing}
        containerRecommendation={containerRecommendation}
        setContainerRecommendation={setContainerRecommendation}
        containerRecommendationErrorCheck={containerRecommendationErrorCheck}
        setContainerRecommendationErrorCheck={
          setContainerRecommendationErrorCheck
        }
        locations={locations}
        canEdit={canEdit}
        isCancelledOrCompleted={isCancelledOrCompleted}
      />
    );
  }

  if (
    workRequestType === WORK_REQUEST_TYPES_ENUM.SPECIAL_ANALYSIS
  ) {
    return (
      <UXDataTableWithoutBody
        tableWidth="100%"
        cols={SPECIAL_ANALYSIS_TABLE_COLUMNS}
        blankFirstHeader={true}
        tableId="midasNumbersTable"
        rowLength={myWorkRequest.workRequestSamples.length}
        enablePaging={false}
        rowsPerPage={0}
        page={0}
        handleChangePage={null}
        handleChangeRowsPerPage={null}
        noDataFoundMessage="No MIDAS Numbers Found"
        enableAddIcon={canEdit}
        addFunction={() => AddNewSampleRow()}
        addToolTipText="Add new sample"
        isDataLoading={false}
      >
        <StyledTableBody key={"WRSamplesTable"}>
          {myWorkRequest.workRequestSamples.map((oWorkRequestSample, index) => {
            const key = `${oWorkRequestSample?.sample?.sampleName}${oWorkRequestSample.existingContainerID}`;
            const samplesSameKey = myWorkRequest.workRequestSamples.filter(f => `${f?.sample?.sampleName}${f.existingContainerID}` === key);
            const totalAmt = getConvertedCurrentAmount(oWorkRequestSample, false, samplesSameKey);
            return (
              <StyledTableRow key={`WRSamplesTable~Row${index}`}>
                <StyledTableCell component="th" scope="row">
                  {index > 0 && !oWorkRequestSample.id && canEdit && (
                    <Tooltip title="Delete Sample" placement="right">
                      <TrashIcon
                        onClick={() => DeleteSampleRow(index)}
                      ></TrashIcon>
                    </Tooltip>
                  )}
                </StyledTableCell>
  
                <StyledTableCell align="left">
                  <ValidatedMidasNumberTextField
                    isDisabled={!canEdit || oWorkRequestSample.id}
                    margin="none"
                    showLabel={true}
                    fontSize={12}
                    fieldWidth="150px"
                    midasNumberObject={oWorkRequestSample.sample}
                    setMidasNumberObject={(e) => {
                      updateData_WorkRequestSample("sample", e, index);
                    }}
                    hasErrors={tempHasErrors}
                    setHasErrors={setTempHasErrors}
                    chemIDToMatch={null}
                  />
                </StyledTableCell>
  
                <StyledTableCell align="right">
                  <Box display={"flex"} alignItems={"center"}>
                    <Box>
                      <StyledAutocomplete
                        renderOption={(props2, option) => (
                          <Option {...props2}>{option.containerNumber}</Option>
                        )}
                        disabled={
                          oWorkRequestSample.sample === null || !canEdit
                        }
                        disablePortal
                        noOptionsText={"No Containers Found"}
                        options={oWorkRequestSample?.sample?.containers.filter(x => x.containerStatusName === 'Confirmed' || x.id === oWorkRequestSample?.existingContainerID) ?? []}
                        onChange={(e, value) => {
                          updateData_WorkRequestSample(
                            "existingContainerID",
                            value?.id,
                            index
                          );
                        }}
                        value={oWorkRequestSample?.existingContainerID}
                        getOptionLabel={(option) => {
                          return option?.containerNumber
                            ? option.containerNumber.toString()
                            : oWorkRequestSample?.sample?.containers.length > 0
                            ? oWorkRequestSample?.sample?.containers
                                .find((f) => f.id === option)
                                ?.containerNumber.toString()
                            : ``;
                        }}
                        isOptionEqualToValue={(option, value) =>
                          value?.id === option?.id
                        }
                        autoHighlight
                        style={{ marginBottom: "5px" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100px" }}
                            variant="outlined"
                            margin="dense"
                            size="small"
                            error={
                              oWorkRequestSample.sample &&
                              !oWorkRequestSample.existingContainerID &&
                              isFieldRequired(
                                myWorkRequest.typeName,
                                "existingContainerID"
                              )
                            }
                            inputProps={{
                              ...params.inputProps,
                              style: { fontSize: sampleGridFontSize },
                            }}
                          />
                        )}
                      />
                    </Box>
  
                    {oWorkRequestSample.sample !== null &&
                      oWorkRequestSample.sample?.containers.length > 0 && (
                        <Box>
                          <IconButton
                            type="button"
                            aria-label="search"
                            onClick={() => {
                              oWorkRequestSample.sample !== null &&
                              oWorkRequestSample.sample?.containers.length > 0
                                ? handleLocateContainerOpen(true, oWorkRequestSample?.sample?.sampleName, (oWorkRequestSample.sample === null || !canEdit))
                                : handleLocateContainerOpen(false, '');
                            }}
                          >
                            <SearchIcon style={{ fill: "blue" }} />
                          </IconButton>
                        </Box>
                      )}
                  </Box>
                </StyledTableCell>
                {/* Description */}
                <TableCell
                  style={{ width: "200px", fontSize: sampleGridFontSize }}
                  align="left"
                >
                  {oWorkRequestSample.sample
                    ? oWorkRequestSample.sample.description
                    : ""}
                </TableCell>
  
                {/* PSIMS PSIMSIDID */}
                <StyledTableCell
                  style={{ fontSize: sampleGridFontSize }}
                  align="left"
                >
                  {oWorkRequestSample.sample && oWorkRequestSample.sample.psimsid
                    ? oWorkRequestSample.sample.psimsid
                    : ""}
                </StyledTableCell>
  
                {/* UoM */}
                <StyledTableCell align="right">
                  <StyledAutocomplete
                    renderOption={(props2, option) => (
                      <Option {...props2}>{option.uoMName}</Option>
                    )}
                    disabled={oWorkRequestSample.sample === null || !canEdit}
                    disablePortal
                    noOptionsText={
                      unitOfMeasureLoading ? "Loading UoMs..." : "No UoMs Found"
                    }
                    options={unitOfMeasure}
                    onChange={(e, value) => {
                      updateData_WorkRequestSample(
                        "requestedAmountUoM",
                        value,
                        index
                      );
                    }}
                    value={oWorkRequestSample.requestedAmountUoM}
                    getOptionLabel={(option) =>
                      option?.uoMName
                        ? option.uoMName
                        : unitOfMeasure.length > 0
                        ? unitOfMeasure.find((f) => f.uoMName === option)?.uoMName
                        : ``
                    }
                    isOptionEqualToValue={(option, value) =>
                      value?.uoMName === option?.uoMName
                    }
                    autoHighlight
                    style={{ marginBottom: "5px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "100px" }}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        error={
                          oWorkRequestSample.sample &&
                          !oWorkRequestSample.requestedAmountUoM
                        }
                        inputProps={{
                          ...params.inputProps,
                          style: { fontSize: sampleGridFontSize },
                        }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                </StyledTableCell>
                {/* Original Amt.s */}
                <StyledTableCell align="left">
                {getOriginalAmount(totalAmt, oWorkRequestSample)}
                </StyledTableCell>
                {/* Requested Split Size */}
                <StyledTableCell align="left">
                  <TextField
                    style={{ width: "75px", marginTop: "5px" }}
                    disabled={oWorkRequestSample.sample === null || !canEdit}
                    size="small"
                    margin="dense"
                    variant="outlined"
                    inputProps={{ style: { fontSize: sampleGridFontSize } }}
                    error={
                      oWorkRequestSample.sample &&
                      !oWorkRequestSample.requestedAmount
                    }
                    InputLabelProps={{ shrink: true }}
                    value={oWorkRequestSample.requestedAmount ?? ""}
                    onChange={(e) => {
                      var numberRegex = /^\d*\.?\d*$/;
                      if (
                        numberRegex.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        const totalAmtValue = parseFloat(totalAmt.split(" ")[0]);
                        const currentValue = e.target.value ? parseFloat(e.target.value) : null;
                        const totalAvailable = parseFloat(oWorkRequestSample.requestedAmount ?? 0) + totalAmtValue
                        let value = null;
                        if (currentValue || currentValue === 0) {
                          value =
                            currentValue > totalAvailable
                              ? totalAvailable < 0 ? 0 :
                                totalAvailable
                                  : e.target.value;
                        }
  
                        updateData_WorkRequestSample(
                          "requestedAmount",
                          value,
                          index
                        );
                      }
                    }}
                  />
                </StyledTableCell>
  
                {/* Amount Available */}
                <StyledTableCell
                  style={{ width: "200px", fontSize: sampleGridFontSize }}
                  align="left"
                >
                  {totalAmt}
                </StyledTableCell>
  
                {
                  <LocateContainer
                    open={locateContainerIsOpen && locateContainerMidas === oWorkRequestSample?.sample?.sampleName}
                    setOpen={handleLocateContainerOpen}
                    incomingUoMObject={null}
                    midasNumber={oWorkRequestSample?.sample?.sampleName}
                    setSelectedContainer={(value) => {
                      updateData_WorkRequestSample(
                        "existingContainerID",
                        value.id,
                        index
                      );
                    }}
                    updateAllMethods={null}
                    methodInfo={null}
                    allowAllSites={true}
                    onlySearch={locateContainerOnlySearch}
                  />
                }                
              </StyledTableRow>
            );
          })}
        </StyledTableBody>
      </UXDataTableWithoutBody>
    );
  }

  return (
    <UXDataTableWithoutBody
      tableWidth="100%"
      cols={myWorkRequest.id ? MySampleInfoCols : MySampleInfoCols.slice(0, -1)}
      blankFirstHeader={true}
      tableId="midasNumbersTable"
      rowLength={myWorkRequest.workRequestSamples.length}
      enablePaging={false}
      rowsPerPage={0}
      page={0}
      handleChangePage={null}
      handleChangeRowsPerPage={null}
      noDataFoundMessage="No MIDAS Numbers Found"
      enableAddIcon={canEdit}
      addFunction={() => AddNewSampleRow()}
      addToolTipText="Add new sample"
      isDataLoading={false}
    >
      <StyledTableBody key={"WRSamplesTable"}>
        {myWorkRequest.workRequestSamples.map((oWorkRequestSample, index) => {
          const key = `${oWorkRequestSample?.sample?.sampleName}${oWorkRequestSample.existingContainerID}`;
          const samplesSameKey = myWorkRequest.workRequestSamples.filter(f => `${f?.sample?.sampleName}${f.existingContainerID}` === key);
          const totalAmt = getConvertedCurrentAmount(oWorkRequestSample, false, samplesSameKey);
          return (
            <StyledTableRow key={`WRSamplesTable~Row${index}`}>
              <StyledTableCell component="th" scope="row">
                {index > 0 && !oWorkRequestSample.id && canEdit && (
                  <Tooltip title="Delete Sample" placement="right">
                    <TrashIcon
                      onClick={() => DeleteSampleRow(index)}
                    ></TrashIcon>
                  </Tooltip>
                )}
              </StyledTableCell>

              <StyledTableCell align="left">
                <ValidatedMidasNumberTextField
                  isDisabled={!canEdit || oWorkRequestSample.id}
                  margin="none"
                  showLabel={true}
                  fontSize={12}
                  fieldWidth="150px"
                  midasNumberObject={oWorkRequestSample.sample}
                  setMidasNumberObject={(e) => {
                    updateData_WorkRequestSample("sample", e, index);
                  }}
                  hasErrors={tempHasErrors}
                  setHasErrors={setTempHasErrors}
                  chemIDToMatch={null}
                />
              </StyledTableCell>

              <StyledTableCell align="right">
                <Box display={"flex"} alignItems={"center"}>
                  <Box>
                    <StyledAutocomplete
                      renderOption={(props2, option) => (
                        <Option {...props2}>{option.containerNumber}</Option>
                      )}
                      disabled={
                        oWorkRequestSample.sample === null || !canEdit
                      }
                      disablePortal
                      noOptionsText={"No Containers Found"}
                      options={oWorkRequestSample?.sample?.containers.filter(x => x.containerStatusName === 'Confirmed' || x.id === oWorkRequestSample?.existingContainerID) ?? []}
                      onChange={(e, value) => {
                        updateData_WorkRequestSample(
                          "existingContainerID",
                          value?.id,
                          index
                        );
                      }}
                      value={oWorkRequestSample?.existingContainerID}
                      getOptionLabel={(option) => {
                        return option?.containerNumber
                          ? option.containerNumber.toString()
                          : oWorkRequestSample?.sample?.containers.length > 0
                          ? oWorkRequestSample?.sample?.containers
                              .find((f) => f.id === option)
                              ?.containerNumber.toString()
                          : ``;
                      }}
                      isOptionEqualToValue={(option, value) =>
                        value?.id === option?.id
                      }
                      autoHighlight
                      style={{ marginBottom: "5px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ width: "100px" }}
                          variant="outlined"
                          margin="dense"
                          size="small"
                          error={
                            oWorkRequestSample.sample &&
                            !oWorkRequestSample.existingContainerID &&
                            isFieldRequired(
                              myWorkRequest.typeName,
                              "existingContainerID"
                            )
                          }
                          inputProps={{
                            ...params.inputProps,
                            style: { fontSize: sampleGridFontSize },
                          }}
                        />
                      )}
                    />
                  </Box>

                  {oWorkRequestSample.sample !== null &&
                    oWorkRequestSample.sample?.containers.length > 0 && (
                      <Box>
                        <IconButton
                          type="button"
                          aria-label="search"
                          onClick={() => {
                            oWorkRequestSample.sample !== null &&
                            oWorkRequestSample.sample?.containers.length > 0
                              ? handleLocateContainerOpen(true, oWorkRequestSample?.sample?.sampleName, (oWorkRequestSample.sample === null || !canEdit))
                              : handleLocateContainerOpen(false, '');
                          }}
                        >
                          <SearchIcon style={{ fill: "blue" }} />
                        </IconButton>
                      </Box>
                    )}
                </Box>
              </StyledTableCell>
              {/* Description */}
              <TableCell
                style={{ width: "200px", fontSize: sampleGridFontSize }}
                align="left"
              >
                {oWorkRequestSample.sample
                  ? oWorkRequestSample.sample.description
                  : ""}
              </TableCell>

              {/* PSIMS PSIMSIDID */}
              <StyledTableCell
                style={{ fontSize: sampleGridFontSize }}
                align="left"
              >
                {oWorkRequestSample.sample && oWorkRequestSample.sample.psimsid
                  ? oWorkRequestSample.sample.psimsid
                  : ""}
              </StyledTableCell>

              {/* UoM */}
              <StyledTableCell align="right">
                <StyledAutocomplete
                  renderOption={(props2, option) => (
                    <Option {...props2}>{option.uoMName}</Option>
                  )}
                  disabled={oWorkRequestSample.sample === null || !canEdit}
                  disablePortal
                  noOptionsText={
                    unitOfMeasureLoading ? "Loading UoMs..." : "No UoMs Found"
                  }
                  options={unitOfMeasure}
                  onChange={(e, value) => {
                    updateData_WorkRequestSample(
                      "requestedAmountUoM",
                      value,
                      index
                    );
                  }}
                  value={oWorkRequestSample.requestedAmountUoM}
                  getOptionLabel={(option) =>
                    option?.uoMName
                      ? option.uoMName
                      : unitOfMeasure.length > 0
                      ? unitOfMeasure.find((f) => f.uoMName === option)?.uoMName
                      : ``
                  }
                  isOptionEqualToValue={(option, value) =>
                    value?.uoMName === option?.uoMName
                  }
                  autoHighlight
                  style={{ marginBottom: "5px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ width: "100px" }}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      error={
                        oWorkRequestSample.sample &&
                        !oWorkRequestSample.requestedAmountUoM
                      }
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: sampleGridFontSize },
                      }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
              </StyledTableCell>
              {/* Original Amt.s */}
              <StyledTableCell align="left">
                {getOriginalAmount(totalAmt, oWorkRequestSample)}
                </StyledTableCell>

              {/* Requested Split Size */}
              <StyledTableCell align="left">
                <TextField
                  style={{ width: "75px", marginTop: "5px" }}
                  disabled={oWorkRequestSample.sample === null || !canEdit}
                  size="small"
                  margin="dense"
                  variant="outlined"
                  inputProps={{ style: { fontSize: sampleGridFontSize } }}
                  error={
                    oWorkRequestSample.sample &&
                    !oWorkRequestSample.requestedAmount
                  }
                  InputLabelProps={{ shrink: true }}
                  value={oWorkRequestSample.requestedAmount ?? ""}
                  onChange={(e) => {
                    var numberRegex = /^\d*\.?\d*$/;
                    if (
                      numberRegex.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      const totalAmtValue = parseFloat(totalAmt.split(" ")[0]);
                      const currentValue = e.target.value ? parseFloat(e.target.value) : null;
                      const totalAvailable = parseFloat(oWorkRequestSample.requestedAmount ?? 0) + totalAmtValue
                      let value = null;
                      if (currentValue || currentValue === 0) {
                        value =
                          currentValue > totalAvailable
                            ? totalAvailable < 0 ? 0 :
                              totalAvailable
                                : e.target.value;
                      }

                      updateData_WorkRequestSample(
                        "requestedAmount",
                        value,
                        index
                      );
                    }
                  }}
                />
              </StyledTableCell>

              {/* Amount Available */}
              <StyledTableCell
                style={{ width: "200px", fontSize: sampleGridFontSize }}
                align="left"
              >
                {totalAmt}
              </StyledTableCell>

              {
                <LocateContainer
                  open={locateContainerIsOpen && locateContainerMidas === oWorkRequestSample?.sample?.sampleName}
                  setOpen={handleLocateContainerOpen}
                  incomingUoMObject={null}
                  midasNumber={oWorkRequestSample?.sample?.sampleName}
                  setSelectedContainer={(value) => {
                    updateData_WorkRequestSample(
                      "existingContainerID",
                      value.id,
                      index
                    );
                  }}
                  updateAllMethods={null}
                  methodInfo={null}
                  allowAllSites={true}
                  onlySearch={locateContainerOnlySearch}
                />
              }
              {/* Location */}
              <StyledTableCell align="right" width={"180px"}>
                <div style={{ display: "flex" }}>
                  <StyledAutocomplete
                    fullWidth
                    sx={{
                      width: "150px",
                      marginRight: 0,
                    }}
                    renderOption={(props2, option) => (
                      <Option {...props2}>{option.locationName}</Option>
                    )}
                    disabled={
                      oWorkRequestSample.sample === null || !canEdit
                    }
                    disablePortal
                    noOptionsText={
                      locationsLoading
                        ? "Loading Locations..."
                        : "No Locations Found"
                    }
                    options={locations}
                    onChange={(e, value) =>
                      updateData_WorkRequestSample(
                        "requestedLocationName",
                        value?.locationName,
                        index
                      )
                    }
                    value={oWorkRequestSample.requestedLocationName}
                    getOptionLabel={(option) =>
                      option?.locationName
                        ? option.locationName
                        : locations.length > 0
                        ? locations.find((f) => f.locationName === option)
                            ?.locationName
                        : ``
                    }
                    isOptionEqualToValue={(option, value) =>
                      value?.locationName === option?.locationName
                    }
                    autoHighlight
                    style={{ marginBottom: "5px" }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        style={{ width: "150px" }}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        error={
                          oWorkRequestSample.sample &&
                          !oWorkRequestSample.requestedLocationName
                        }
                        inputProps={{
                          ...params.inputProps,
                          style: { fontSize: sampleGridFontSize },
                        }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                  {/* <IconButton
                    edge="end"
                    sx={{ p: "4px" }}
                    onClick={() => {
                      window.open("https://goto/asl");
                    }}
                  >
                    <OpenInNewIcon />
                  </IconButton> */}
                </div>
              </StyledTableCell>
              {myWorkRequest.id && (
                <StyledTableCell>
                  <TextField
                    // disabled={
                    //   oWorkRequestSample.sample === null ||
                    //   !canEdit ||
                    //   !(
                    //     usingContainerRegistration ||
                    //     usingContainerRegistrationWTest
                    //   )
                    // }
                    value={oWorkRequestSample.deliveryLocationSublocation}
                    margin="dense"
                    size="small"
                    onChange={(v) => {
                      if (v?.target?.value) {
                        updateData_WorkRequestSample(
                          "deliveryLocationSublocation",
                          v.target.value,
                          index
                        );
                      }
                    }}
                  />
                </StyledTableCell>
              )}
            </StyledTableRow>
          );
        })}
      </StyledTableBody>
    </UXDataTableWithoutBody>
  );
}
